<template>
  <!-- 导入订单失败组件 -->
  <a-modal
    title="导入失败"
    width="40%"
    :visible="modalVisible"
    okText="取消"
    @ok="ok"
    :cancel-button-props="{ style: { display: 'none' } }"
  >
    <div class="text">
      当前表格中有{{ failCount }}个错误,
      <span>请下载新的表格查看错误原因,并修改完成后重新上传</span>
    </div>
    <div class="btns">
      <a-button type="primary" @click="dowmExcel"> 下载表格 </a-button>
      <OrderImport text="重新上传" @errOrderFn="errOrderFn" />
    </div>
  </a-modal>
</template>

<script>
import OrderImport from "@/components/file/OrderImport.vue";
export default {
  components: { OrderImport },
  props: ["modalVisible", "errImportUrl", "failCount"],
  data() {
    return {};
  },
  methods: {
    dowmExcel() {
      window.location.href = this.errImportUrl; // 下载错误表格
    },
    errOrderFn(data) {
      this.$emit("errOrderFn", data);
    },
    ok() {
      this.$emit("modalCancel");
      this.$emit("spinningFn", false);
    },
  },
};
</script>
 
<style lang="less" scoped>
.text {
  text-align: center;
  span {
    color: red;
  }
}
.btns {
  display: flex;
  margin-top: 50px;
  margin-left: 30%;
  .ant-btn {
    margin-right: 30px;
  }
}
// /deep/.ant-modal-footer button + button {
//   display: none;
// }
/deep/.ant-modal-close-icon {
  display: none;
}
</style>