<!--
 * @Author: dengqiuju@baidu.com
 * @Date: 2022-01-19 00:28:49
 * @Description: 编辑收货地址弹窗
-->
<template>
  <!-- 修改收货地址弹框 -->
  <a-modal
    title="修改收货地址"
    width="40%"
    :visible="modalVisible"
    okText="确定"
    cancelText="取消"
    @ok="ok"
    @cancel="
      () => {
        $emit('cancel');
      }
    "
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      labelAlign="left"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 10 }"
    >
      <a-form-model-item label="收件人" prop="receiptName">
        <a-input 
          v-model="form.receiptName"
          placeholder="请输入收件人"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="receiptPhone">
        <a-input 
          v-model="form.receiptPhone"
          placeholder="请输入手机号"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="所在地区" prop="receiptArea">
        <a-input 
          v-model="form.receiptArea"
          placeholder="请选择地区"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="receiptAddress">
        <a-textarea 
          v-model="form.receiptAddress"
          placeholder="请输入详细地址"
          :maxLength="300"
          :rows="2"
        ></a-textarea>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  ShowAllProvinceApi,
  GetAllCityByCodeApi,
  GetAllCountryByCityApi
} from "@/request/api/orderformManage";
export default {
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    record(newVal) {
      this.form = newVal;
    },
  },
  data() {
    return {
      provinceName: "", // 省级名字
      cityName: "", // 市级名字
      countryName: "", // 县级名
      provinceCodeArr: [], // 省级数组
      cityCodeArr: [], // 市级数组
      countryCodeArr: [], // 县级数组
      form: {},
      rules: {
        receiptName: [
          {
            required: true,
            message: "请输入收件人",
            trigger: "blur",
          }
        ],
        receiptPhone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            validator: this.validatePhone,
            trigger: "blur"
          }
        ],
        receiptAddress: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          }
        ]
      }
    };
  },
  created() {
    // this.ShowAllProvinceFn();
  },
  methods: {
    ok() {
      // this.form.receiptArea = this.provinceName
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) return
        this.$emit("ok", this.form)
      })
    },
    // 手机号校验规则
    validatePhone(rule, value, callback) {
      let reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (value === "") {
        callback("输入的内容不能为空");
      } else if (!reg.test(value)) {
        callback("请输入正确的手机号");
      } else {
        callback();
      }
    },
    clearCityCode() {
      this.form.cityCode = '';
      this.cityName = '';
    },
    clearCountryCode() {
      this.form.countryCode  = '';
      this.countryName = '';
    },
    // 省级改变的回调
    provinceCodeChange(value, option) {
      this.form.cityCode = "";
      this.form.provinceCode = value;
      this.provinceName = option.componentOptions.children[0].text;
      this.clearCityCode();
      this.clearCountryCode();
      this.GetAllCityByCodeFn(value);
    },
    // 市级改变的回调
    cityCodeChange(value, option) {
      this.form.cityCode = value;
      this.cityName = option.componentOptions.children[0].text;
      this.clearCountryCode();
      this.getAllCountryByCityFn(value);
    },
    // 县级改变的回调
    countryCodeChange(value, option) {
      this.form.countryCode  = value;
      this.countryName = option.componentOptions.children[0].text;
    },
    // 查询所有省份函数
    ShowAllProvinceFn() {
      ShowAllProvinceApi().then(({ code, data }) => {
        if (code == 200) this.provinceCodeArr = data;
      });
    },
    // 根据省级code获取所有市级函数
    GetAllCityByCodeFn(provinceCode) {
      GetAllCityByCodeApi({ code: provinceCode }).then(({ code, data }) => {
        if (code == 200) this.cityCodeArr = data;
      });
    },
    getAllCountryByCityFn(cityCode) {
      GetAllCountryByCityApi({code: cityCode}).then(({code, data}) => {
        if (code === 200) this.countryCodeArr = data;
      });
    },
  }
};
</script>
 
<style lang="less" scoped>
.input {
  width: 400px;
  margin-left: 20px;
}
</style>