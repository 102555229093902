<!--
 * @Author: dengqiuju@baidu.com
 * @Date: 2022-01-25 13:35:14
 * @Description: 导入快递单号
-->
<template>
  <!-- 导入快递单号组件 -->
  <a-upload
    name="file"
    :action="uploadUrl"
    list-type="picture-card"
    :before-upload="beforeUpload"
    :showUploadList="false"
    :headers="headers"
    @change="uploadFile"
    accept="application/vnd.ms-excel, 
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  >
    <a-button
      icon="import"
      type="primary"
      :loading="uploadLoading"
      :disabled="uploadLoading"
    >
      {{ text }}
    </a-button>
  </a-upload>
</template>

<script>
import { message } from "ant-design-vue";
import { domainName } from "@/config/index";
export default {
  props: {
    text: {
      type: String,
      default: "导入快递单号",
    },
    adminV2: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploadLoading: false,
      uploadUrl: `${domainName}/admin/order/info/import/express`,
      headers: {
        accessToken: localStorage.getItem("token"),
      },
    };
  },
  created() {
    if (this.adminV2) this.uploadUrl = `${domainName}adminv2/shoporder/expressUpload`
  },
  methods: {
    uploadFile(info) {
      console.log(info);
      if (info.file.status !== "done") {
        this.uploadLoading = true;
        this.$emit("spinningFn", true);
      }
      if (!info.file.status) {
        this.uploadLoading = false;
        this.$emit("spinningFn", false);
      }
      if (info.file.status === "done") {
        this.uploadLoading = false;
        let { code, data, msg } = info.file.response;
        if (code === 200) {
          this.$emit("errOrderFn", data);
        } else {
          message.error(msg);
          this.$emit("spinningFn", false);
        }
      }
      if (info.file.status == "error") {
        this.uploadLoading = false;
        message.error("导入快递单号失败");
        this.$emit("spinningFn", false);
      }
    },
    beforeUpload(file) {
      this.$emit("spinningFn", false);
      this.uploadLoading = true;
      const isJpgOrPng =
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        "application/vnd.ms-excel";
      if (!isJpgOrPng) {
        message.error("不能上传其他类型的文件");
      } else {
      }
      return isJpgOrPng;
    },
  },
};
</script>
 
<style lang="less" scoped>
/deep/.ant-upload.ant-upload-select-picture-card {
  width: auto;
  height: auto;
  border: none;
}
/deep/.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
}
</style>