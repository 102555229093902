<template>
  <!-- 订单列表页面 -->
  <div class="padding24 color_white" id="order_list">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div v-show="$route.meta.isShow">
        <a-form layout="inline">
          <a-form-item label="订单编号">
            <a-input v-model="orderNo" placeholder="请输入订单编号" @pressEnter="searchClick"></a-input>
          </a-form-item>
          <a-form-item label="用户账号">
            <a-input v-model="userName" placeholder="请输入用户账号" @pressEnter="searchClick"></a-input>
          </a-form-item>
          <a-form-item label="商品名称">
            <a-input v-model="name" placeholder="请输入商品名称" @pressEnter="searchClick"></a-input>
          </a-form-item>

          <a-form-item label="商品类型">
            <a-select v-model="orderType" :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            " @change="changeOrderType">
              <a-select-option v-for="item in orderTypeArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="订单状态">
            <a-select v-model="orderStatus" :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            " @change="changeOrderStatus">
              <a-select-option v-for="item in orderStatusArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="支付状态">
            <a-select v-model="orderPayStatus" :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            " @change="changeOrderPayStatus">
              <a-select-option v-for="item in orderPayStatusArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="支付方式">
            <a-select v-model="orderPayType" :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            " @change="changeOrderPayType">
              <a-select-option v-for="item in orderPayTypeArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="批改服务状态">
            <a-select v-model="correctFlag" :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            " @change="changeCorrectFlag">
              <a-select-option v-for="item in correctFlagArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item v-if="ascriptionFlag == 0" label="归属地">
            <a-select style="width: 120px" v-model="province" :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            " @change="changeProvince">
              <a-select-option v-for="item in provinceArr" :key="item.code">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-select style="width: 120px" v-model="city" :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            " @change="changeCity">
              <a-select-option v-for="item in cityArr" :key="item.code">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="下单日期">
            <a-range-picker :value="rangePicker" @change="onChangeDate" />
          </a-form-item>

          <a-form-item label="物流状态">
            <a-select style="width: 120px;" v-model="deliveryStatus"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode || document.body"
              @change="changedeliveryStatus">
              <a-select-option v-for="item in deliveryStatusArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-button icon="search" type="primary" @click="searchClick" :disabled="isDisableSearch">
              搜索
            </a-button>
          </a-form-item>
          <a-form-item>
            <a-button icon="redo" type="primary" @click="resetClick">
              重置
            </a-button>
          </a-form-item>
        </a-form>
        <div class="top">
          <!-- <div class="top_line">
                      <span>
                        订单编号 :
                        <a-input
                          v-model="orderNo"
                          placeholder="请输入订单编号"
                          @pressEnter="searchClick"
                        ></a-input>
                      </span>
                      <span>
                        用户账号 :
                        <a-input
                          v-model="userName"
                          placeholder="请输入用户账号"
                          @pressEnter="searchClick"
                        ></a-input
                      ></span>
                      <span>
                        商品名称 :
                        <a-input
                          v-model="name"
                          placeholder="请输入商品名称"
                          @pressEnter="searchClick"
                        ></a-input>
                      </span>
                      <span>
                        商品类型 :
                        <a-select
                          v-model="orderType"
                          :getPopupContainer="
                            (triggerNode) => triggerNode.parentNode || document.body
                          "
                          @change="changeOrderType"
                        >
                          <a-select-option v-for="item in orderTypeArr" :key="item.key">
                            {{ item.value }}
                          </a-select-option>
                        </a-select>
                      </span>
                      <span
                        >订单状态 :
                        <a-select
                          v-model="orderStatus"
                          :getPopupContainer="
                            (triggerNode) => triggerNode.parentNode || document.body
                          "
                          @change="changeOrderStatus"
                        >
                          <a-select-option v-for="item in orderStatusArr" :key="item.key">
                            {{ item.value }}
                          </a-select-option>
                        </a-select>
                      </span>
                    </div>
                    <div class="top_line">
                      <span
                        >支付状态 :
                        <a-select
                          v-model="orderPayStatus"
                          :getPopupContainer="
                            (triggerNode) => triggerNode.parentNode || document.body
                          "
                          @change="changeOrderPayStatus"
                        >
                          <a-select-option
                            v-for="item in orderPayStatusArr"
                            :key="item.key"
                          >
                            {{ item.value }}
                          </a-select-option>
                        </a-select>
                      </span>
                      <span
                        >支付方式 :
                        <a-select
                          v-model="orderPayType"
                          :getPopupContainer="
                            (triggerNode) => triggerNode.parentNode || document.body
                          "
                          @change="changeOrderPayType"
                        >
                          <a-select-option
                            v-for="item in orderPayTypeArr"
                            :key="item.key"
                          >
                            {{ item.value }}
                          </a-select-option>
                        </a-select>
                      </span>
                      <span
                        >批改服务状态 :
                        <a-select
                          v-model="correctFlag"
                          :getPopupContainer="
                            (triggerNode) => triggerNode.parentNode || document.body
                          "
                          @change="changeCorrectFlag"
                        >
                          <a-select-option v-for="item in correctFlagArr" :key="item.key">
                            {{ item.value }}
                          </a-select-option>
                        </a-select>
                      </span>
                      <span v-if="ascriptionFlag == 0"
                        >归属地 :
                        <a-select
                          v-model="province"
                          :getPopupContainer="
                            (triggerNode) => triggerNode.parentNode || document.body
                          "
                          @change="changeProvince"
                        >
                          <a-select-option v-for="item in provinceArr" :key="item.code">
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                        <a-select
                          v-model="city"
                          :getPopupContainer="
                            (triggerNode) => triggerNode.parentNode || document.body
                          "
                          @change="changeCity"
                        >
                          <a-select-option v-for="item in cityArr" :key="item.code">
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </span>
                      <span
                        >下单日期 :<a-range-picker
                          :value="rangePicker"
                          @change="onChangeDate"
                        />
                      </span>
                      <span
                        >物流状态:
                        <a-select
                          v-model="correctFlag"
                          :getPopupContainer="
                            (triggerNode) => triggerNode.parentNode || document.body
                          "
                          @change="changeCorrectFlag"
                        >
                          <a-select-option v-for="item in correctFlagArr" :key="item.key">
                            {{ item.value }}
                          </a-select-option>
                        </a-select>
                      </span>
                      <span>
                        <a-button
                          icon="search"
                          type="primary"
                          @click="searchClick"
                          :disabled="isDisableSearch"
                        >
                          搜索
                        </a-button>
                        <a-button icon="redo" type="primary" @click="resetClick">
                          重置
                        </a-button>
                      </span>
                    </div> -->
          <div class="top_line">
            <a-button v-if="isShowOpen" icon="folder-open" type="primary" :loading="isDisOpenCorrectingService"
              :disabled="isDisOpenCorrectingService" @click="correctingServiceClick">
              开通批改服务
            </a-button>
            <a-button v-if="isShowAllOpen" icon="form" type="primary" :loading="isDisOpenAll" :disabled="isDisOpenAll"
              @click="allOpenClick">
              全部开通批改服务
            </a-button>

            <a-button icon="cloud-download" v-if="isShowDownload" type="primary" :disabled="isDisDownTemplate"
              @click="downloadTemplateclick">
              下载模板
            </a-button>
            <!-- 导入订单 -->
            <OrderformList v-if="isShowOrderImport" @errOrderFn="errOrderFn" @spinningFn="spinningFn" />
            <!-- 导入快递单号 -->
            <ExpressNoImport v-if="isShowExpressNoImport" @errOrderFn="errOrderFn" @spinningFn="spinningFn" />
            <a-button v-if="isShowOrderExport" icon="export" type="primary" :disabled="isDisAllOrder"
              @click="exportAllOrder">
              导出全部订单
            </a-button>
          </div>
        </div>
        <!-- 表格 -->
        <a-table :columns="columns" :data-source="tableData" :pagination="false" :loading="tableLoading"
          :scroll="{ x: 2400, y: 520 }" :row-selection="rowSelection">
          <span slot="businessType" slot-scope="businessType">
            <span>{{ businessType == 1 ? "课程" : "会员" }}</span>
          </span>
          <span slot="userName" slot-scope="userName, record">
            <span @click="userClick(record)">{{ record.userName }} </span>
          </span>
          <span slot="thirdPartyChannel" slot-scope="thirdPartyChannel, record">
            <span>{{ thirdPartyChannelMap[record.thirdPartyChannel] || '——' }}</span>
            <!-- <span v-show="record.thirdPartyChannel == 0"> —— </span>
                        <span v-show="record.thirdPartyChannel == 1"> 抖店 </span>
                        <span v-show="record.thirdPartyChannel == 2"> 小鹅通 </span>
                        <span v-show="record.thirdPartyChannel == 3"> 有赞 </span>
                        <span v-show="record.thirdPartyChannel == 4"> 快手 </span>
                        <span v-show="record.thirdPartyChannel == 5"> 视频号 </span>
                        <span v-show="record.thirdPartyChannel == 6"> 螳螂 </span> -->
          </span>
          <span slot="correctFlag" slot-scope="correctFlag">
            <span v-show="correctFlag == 0">未开通</span>
            <span v-show="correctFlag == 1">已开通</span>
          </span>
          <div slot="receiptAddress" slot-scope="receiptAddress, record">
            <template v-if="!record.receiptName && !record.receiptPhone && !record.receiptArea && !record.receiptAddress">
              <span>——</span>
            </template>
            <template v-else>
              <p>{{ record.receiptName }} {{ record.receiptPhone }}</p>
              <p>{{ record.receiptArea }} {{ record.receiptAddress }}</p>
            </template>
          </div>
          <div slot="deliveryStatus" slot-scope="deliveryStatus, record">
            <p v-if="record.thirdPartyOrderNo">——</p>
            <p v-else>{{ deliveryStatusText[deliveryStatus] }}</p>
          </div>
          <span slot="operation" slot-scope="operation, record">
            <a v-if="
              (record.payTypeText == '微信' &&
                record.orderStatusText == '已购买' &&
                record.refundStatus == '0' &&
                isShowRefund) ||
              (record.payTypeText == '余额支付' &&
                record.orderStatusText == '已购买' &&
                record.refundStatus == '0' &&
                isShowRefund) ||
              (record.payTypeText == '支付宝' &&
                record.orderStatusText == '已购买' &&
                record.refundStatus == '0' &&
                isShowRefund)
            " class="margin_right20" @click="refundClick(record)">
              申请退款
            </a>
            <a v-if="record.refundStatus == '1' && isShowConfirm" class="margin_right20" @click="refundClick(record)">
              确认退款
            </a>
            <a v-if="record.refundStatus == '1' && isShowRefuse" class="margin_right20" @click="rejectClick(record)">
              驳回申请退款
            </a>
            <a v-if="record.orderStatusText == '已购买' && isShowRights" class="margin_right20"
              @click="recoveryRightsClick(record)">回收权益</a>
            <a-dropdown placement="bottomCenter" v-if="
              isShowExpressNo || isShowThreeNews || isShowOpen || isShowDetail
            ">
              <a class="ant-dropdown-link margin_right20" @click="(e) => e.preventDefault()">
                更多
                <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-if="isShowReceiptAddress">
                  <a class="margin_right20" @click="showReceiptAddressDlg(record)">收货地址</a>
                </a-menu-item>
                <a-menu-item v-if="isShowExpressNo">
                  <a class="margin_right20" @click="expressClick(record)">快递信息</a>
                </a-menu-item>
                <a-menu-item v-if="isShowThreeNews">
                  <a class="margin_right20" @click="threeNewsClick(record)">第三方信息</a>
                </a-menu-item>
                <a-menu-item v-if="
                  record.correctFlag == 0 &&
                  isShowOpen &&
                  record.orderStatusText == '已购买' &&
                  record.businessType == 1
                ">
                  <a class="margin_right20" @click="openCorrectingService(record)">开通批改服务</a>
                </a-menu-item>
                <a-menu-item v-if="isShowDetail">
                  <a @click="orderDetailClick(record)">详情</a>
                </a-menu-item>
                <!-- 订单状态为1未发货或3不可发货时显示 -->
                <a-menu-item
                  v-if="(record.deliveryStatus == 1 || record.deliveryStatus == 3) && isShowChangeDeliveryStatus">
                  <a @click="changeDeliveryStatusClick(record)">修改物流状态</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="orderMarkClick(record)">备注</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <!-- 分页功能 -->
        <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading" />
        <EditAddressModal :record="currentRecord" :modalVisible="addressModalVisible"
          @cancel="() => (addressModalVisible = false)" @ok="updateOrderReceiptAddress">

        </EditAddressModal>
        <!-- 快递单号的弹框 -->
        <EditMedal :expressVal="inpVal" :modalVisible="modalVisible" @cancel="() => (modalVisible = false)" @ok="ok" />
        <!-- 第三方信息的弹框 -->
        <ThreeNewsModal v-if="threeNewsVisible" :modalVisible="threeNewsVisible" :form="currentRecord"
          @cancel="() => (threeNewsVisible = false)" @ok="threeNewsOk" />
        <!-- 订单导入失败的弹框 -->
        <ErrImport :modalVisible="errImportVisible" :errImportUrl="errImportUrl" :failCount="failCount"
          @cancel="() => (errImportVisible = false)" @modalCancel="() => (errImportVisible = false)"
          @errOrderFn="errOrderFn" @spinningFn="spinningFn" />
        <!-- 修改物流状态的弹窗 -->
        <a-modal v-model="isShowChangeDeliveryStatusDlg" title="提示" @ok="saveChangeDeliveryStatus">
          <p>{{ changeDeliveryStatusTips }}</p>
        </a-modal>
        <!-- 备注 -->
        <a-modal v-model="isMarkShow" title="备注" @ok="markAddClick">
          <a-textarea v-model="valMark" placeholder="请输入备注" :rows="4" :maxlength="200" />
          <div style="width: 100%;text-align:right">{{ valMark.length }}/200</div>
        </a-modal>
        <!-- 返回顶部 -->
        <a-back-top :target="targetFn" :visibilityHeight="100" />
      </div>
    </a-spin>
    <router-view />
  </div>
</template>
<script>
import axios from "axios";
import { domainName } from "@/config/index";
import { DictionaryApi } from "@/request/api/login";
import {
  GetOrdersApi,
  ShowAllProvinceApi,
  GetAllCityByCodeApi,
  SaveOrderReceiptAddressApi,
  GetOrdersExpressNoApi,
  OrderInfoRefundV2Api,
  DownloadTemplateApi,
  OrderThirdPartyApi,
  OorrectsOpenApi,
  OrderInfoExportApi2,
  GetCanBeOpenedCorrectsApi,
  AllOpenedCorrectsApi,
  SaveDeliveryStatusApi, RecoveryRights,
  ApplyRefundV2Api,
  refuseRefundV2API,
  addRemarkFun
} from "@/request/api/orderformManage";
import MyPagination from "@/components/pagination/MyPagination";
import OrderformList from "@/components/file/OrderImport2.vue";
import ExpressNoImport from "@/components/file/ExpressNoImport.vue";
import ErrImport from "./modules/ErrImport.vue";
import EditAddressModal from "./modules/EditAddressModal";
import EditMedal from "./modules/EditMedal";
import ThreeNewsModal from "./modules/ThreeNewsModal.vue";
import { message, Modal } from "ant-design-vue";
import { codeFn } from "@/utils/tools";

export default {
  created () {
    this.userNo = sessionStorage.getItem("userNo")
      ? sessionStorage.getItem("userNo")
      : "";
    let orders = JSON.parse(sessionStorage.getItem("orders"));
    this.pageNo = localStorage.getItem("pageNo")
      ? Number(localStorage.getItem("pageNo"))
      : 1;
    this.orders = orders ? orders : [];
    setTimeout(() => {
      sessionStorage.removeItem("userNo");
      sessionStorage.removeItem("orders");
      localStorage.removeItem("pageNo");
    }, 3000);
    this.thirdPartyChannelDist();
    this.GetOrdersFn();
    this.ShowAllProvinceFn();
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    dictionaryObj.orderType.forEach((item) => this.orderTypeArr.push(item));
    dictionaryObj.orderStatus.forEach((item) => this.orderStatusArr.push(item));
    dictionaryObj.orderPayType.forEach((item) =>
      this.orderPayTypeArr.push(item)
    );
    dictionaryObj.orderPayStatus.forEach((item) =>
      this.orderPayStatusArr.push(item)
    );
    this.ascriptionFlag = sessionStorage.getItem("ascriptionFlag");
  },
  components: {
    MyPagination,
    EditAddressModal,
    EditMedal,
    OrderformList,
    ExpressNoImport,
    ErrImport,
    ThreeNewsModal,
  },
  mounted () {
    if (codeFn("/admin/order/info")) this.isShowDetail = true;
    if (codeFn("/admin/order/info/saveDeliveryStatus")) this.isShowChangeDeliveryStatus = true;
    if (codeFn("/admin/order/info/apply/refund")) this.isShowRefund = true;
    if (codeFn("/admin/order/info/refuse/refund")) this.isShowRefuse = true;
    if (codeFn("/admin/order/info/refund")) this.isShowConfirm = true;
    if (codeFn("/admin/order/info/saveExpressNo")) this.isShowExpressNo = true;
    if (codeFn("/admin/order/info/saveExpressNo")) this.isShowReceiptAddress = true;
    if (codeFn("/admin/order/info/recovery/rights")) this.isShowRights = true;
    if (codeFn("/admin/order/info/import")) this.isShowOrderImport = true;
    if (codeFn("/admin/order/info/export")) this.isShowOrderExport = true;
    if (codeFn("/admin/order/info/import/express")) this.isShowExpressNoImport = true;
    if (codeFn("/admin/order/info/download/template")) {
      this.isShowDownload = true;
    }
    if (codeFn("/admin/order/info/third/party")) this.isShowThreeNews = true;
    if (codeFn("/admin/corrects/open")) this.isShowOpen = true;
    if (codeFn("/admin/order/info/allOpenedCorrects")) {
      this.isShowAllOpen = true;
    }
    if (codeFn("/admin/app/user/queryAdminUserInfos")) {
      this.isShowUserList = true;
    }
  },
  data () {
    return {
      valMark: '',
      orderNoVal: '',
      isMarkShow: false,
      rangePicker: [], // 下单时间的数组
      spinning: false, // 是否为全局加载中的状态
      tip: "",
      userNo: "",
      orders: [],
      threeNewsVisible: false, // 第三方信息弹框是否显示
      errImportUrl: "", // 订单导入失败的url
      failCount: null, // 订单导入失败的数据
      errImportVisible: false, // 订单导入失败的弹框是否显示
      ascriptionFlag: 1, // 归属地查询是否显示
      currentRecord: {}, // 订单列表的当前项
      addressModalVisible: false, // 修改收货人地址信息弹窗是否显示
      modalVisible: false, // 快递模态框是否显示
      tableLoading: true, // 表格是否是加载中
      count: 0, // 列表数据的总条数
      pageNo: 1,
      pageSize: 20,
      orderNo: "",
      userName: "",
      name: "",
      orderType: "", // 商品类型默认值
      orderTypeArr: [{ key: "", value: "全部类型" }], //商品类型的数组
      orderStatus: 1, // 订单状态的默认值
      orderStatusArr: [{ key: "", value: "全部状态" }], //订单状态的数组
      orderPayStatus: "", // 支付状态的默认值
      orderPayStatusArr: [{ key: "", value: "全部状态" }], // 支付状态的数组
      orderPayType: "", // 支付方式默认值
      orderPayTypeArr: [{ key: "", value: "全部状态" }], // 支付方式的数组
      correctFlag: "", // 批改服务状态
      correctFlagArr: [
        { key: "", value: "全部状态" },
        { key: "0", value: "未开通" },
        { key: "1", value: "已开通" },
      ], // 批改服务状态数组
      deliveryStatus: "", // 物流状态
      deliveryStatusText: {
        0: '——', // 无需物流
        1: '未发货',
        2: '已发货',
        3: '不可发货'
      },
      deliveryStatusArr: [
        { key: "", value: "全部状态" },
        { key: "1", value: "未发货" },
        { key: "2", value: "已发货" },
        { key: "3", value: "不可发货" },
      ], // 物流状态数组
      province: "", // 省级默认值
      provinceArr: [{ code: "", name: "所有省" }], // 省级数组
      city: "", // 市级默认值
      cityArr: [{ code: "", name: "所有市" }], // 市级数组
      startTime: "", // 下单开始时间
      endTime: "", // 下单结束时间
      isDisableSearch: false, // 搜索按钮是否禁用
      inpVal: "", // 快递信息弹框的输入值
      // 表格数据
      columns: [
        {
          title: "商品名称",
          dataIndex: "businessName",
          key: "businessName",
          width: "9%",
          scopedSlots: { customRender: "businessName" },
        },
        {
          title: "订单编号",
          dataIndex: "orderNo",
          key: "orderNo",
          width: "9%",
        },
        {
          title: "商品类型",
          dataIndex: "businessType",
          key: "businessType",
          width: "4%",
          scopedSlots: { customRender: "businessType" },
        },
        {
          title: "商品金额",
          dataIndex: "price",
          key: "price",
          width: "4%",
          scopedSlots: { customRender: "price" },
        },
        {
          title: "实付金额",
          dataIndex: "realPrice",
          key: "realPrice",
          width: "4%",
          scopedSlots: { customRender: "realPrice" },
        },
        {
          title: "用户账号",
          dataIndex: "userName",
          key: "userName",
          width: "8%",
          scopedSlots: { customRender: "userName" },
        },
        {
          title: "支付方式",
          dataIndex: "payTypeText",
          key: "payTypeText",
          width: "5%",
          scopedSlots: { customRender: "payTypeText" },
        },
        {
          title: "订单状态",
          dataIndex: "orderStatusText",
          key: "orderStatusText",
          width: "5%",
          scopedSlots: { customRender: "orderStatusText" },
        },
        {
          title: "批改服务状态",
          dataIndex: "correctFlag",
          key: "correctFlag",
          width: "6%",
          scopedSlots: { customRender: "correctFlag" },
        },
        {
          title: "下单时间",
          dataIndex: "createTime",
          width: "5%",
          sorter: true,
          key: "createTime",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "归属地",
          dataIndex: "region",
          key: "region",
          width: "7%",
          scopedSlots: { customRender: "region" },
        },
        {
          title: "第三方渠道",
          dataIndex: "thirdPartyChannel",
          key: "thirdPartyChannel",
          width: "5%",
          scopedSlots: { customRender: "thirdPartyChannel" },
        },
        {
          title: "快递单号",
          dataIndex: "expressNo",
          key: "expressNo",
          width: "10%",
          scopedSlots: { customRender: "expressNo" },
        },
        {
          title: "收货地址",
          dataIndex: "receiptAddress",
          key: "receiptAddress",
          width: "12%",
          scopedSlots: { customRender: "receiptAddress" },
        },
        {
          title: "物流状态",
          dataIndex: "deliveryStatus",
          key: "deliveryStatus",
          width: "8%",
          scopedSlots: { customRender: "deliveryStatus" },
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
          width: "8%",
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          fixed: "right",
          width: 200,
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      selectedRowKeys: [], // 复选框选中的key集合
      selectedRows: [], // 开通批改服务传回后端的数组
      isDisOpenCorrectingService: false, // 开通批改服务是否禁用
      isDisOpenAll: false, // 全部开通批改服务是否禁用
      isDisDownTemplate: false, // 下载模块是否禁用
      isDisImportOrder: false, // 导入订单是否禁用
      isDisAllOrder: false, // 导入订单是否禁用
      isShowDetail: false, // 详情的按钮是否隐藏
      changeDeliveryStatusVal: 0, // 修改物流状态的值
      changeDeliveryStatusTips: '', // 修改物流状态的提示
      isShowChangeDeliveryStatusDlg: false, // 修改物流状态弹窗是否显示
      isShowChangeDeliveryStatus: false, // 修改物流状态按钮是否显示
      isShowRefund: false, // 申请退款是否隐藏
      isShowRefuse: false, // 驳回退款是否隐藏
      isShowConfirm: false, // 确认退款是否隐藏
      isShowReceiptAddress: false, // 收货地址是否隐藏
      isShowExpressNo: false, // 快递信息是否隐藏
      isShowRights: false, // 回收权益是否隐藏
      isShowOrderImport: false, // 订单导入是否隐藏
      isShowOrderExport: false, // 订单导出是否隐藏
      isShowExpressNoImport: false, // 导入快递单号是否显示
      isShowDownload: false, // 下载模板是否隐藏
      isShowThreeNews: false, // 第三方信息是否隐藏
      isShowOpen: false, // 开通批改服务是否隐藏
      isShowAllOpen: false, // 全部开通批改服务是否隐藏
      isShowUserList: false, // 用户管理列表
      thirdPartyChannelMap: {}, // 第三方渠道
      uploadUrl: `${domainName}/adminv2/order/info/import?action=2`,
      dataList: {},
      timer: "",
      time: 0,
    };
  },
  computed: {
    // 表格相关配置
    rowSelection () {
      let that = this;
      return {
        type: "checkbox",
        selectedRowKeys: that.selectedRowKeys,
        // 复选框列表选中的回调
        onChange: (selectedRowKeys, selectedRows) => {
          that.selectedRowKeys = selectedRowKeys;
          selectedRows.forEach((item) => {
            item.courseNo = item.businessNo;
            item.orderNo = item.orderNo;
            item.phone = item.userName;
          });
          that.selectedRows = selectedRows;
        },
        // 选择框的默认属性配置
        getCheckboxProps: (record) => ({
          props: {
            disabled:
              record.orderStatusText !== "已购买" ||
              record.correctFlag == 1 ||
              record.businessType == 2,
          },
        }),
      };
    },
  },
  methods: {
    orderMarkClick (record) {
      this.isMarkShow = true
      this.valMark = record.remark
      this.orderNoVal = record.orderNo
    },
    markAddClick () {
      let obj = {
        orderNo: this.orderNoVal,
        remark: this.valMark
      }
      addRemarkFun(obj).then(({ code, data }) => {
        if (code == 200) {
          this.isMarkShow = false
          this.GetOrdersFn()
        }
      });
    },
    thirdPartyChannelDist () {
      DictionaryApi().then(({ code, data }) => {
        if (code == 200) {
          let temp = {};
          data.thirdPartyChannel.forEach(item => {
            temp[item.key] = item.value;
          });
          this.thirdPartyChannelMap = temp;
        }
      });
    },
    // 开通全部批改服务按钮
    allOpenClick () {
      let that = this;
      (this.isDisOpenAll = true),
        setTimeout(() => {
          this.isDisOpenAll = false;
        }, 3000);
      if (this.orderType != 1) {
        message.error("请选择商品类型为课程");
      } else if (this.orderStatus != 1) {
        message.error("请选择订单状态为已购买");
      } else if (this.correctFlag != "0") {
        message.error("请选择批改服务状态为未开通");
      } else {
        GetCanBeOpenedCorrectsApi({
          businessType: this.orderType,
          city: this.city,
          correctFlag: this.correctFlag,
          startTime: this.startTime,
          endTime: this.endTime,
          name: this.name,
          orderNo: this.orderNo,
          orderStatus: this.orderStatus,
          payStatus: this.orderPayStatus,
          payType: this.orderPayType,
          province: this.province,
          userName: this.userName,
        }).then(({ code, data }) => {
          if (code == 200) {
            if (data > 1000) {
              message.error("批改订单大于1000,请添加其他条件限制");
            } else if (data <= 0) {
              message.error("筛选的订单数为0, 请重新选择");
            } else {
              Modal.confirm({
                title: "提示",
                content: `是否为选中${data}个订单(用户)开通批改服务`,
                okText: "确认",
                cancelText: "取消",
                width: "500px",
                onOk () {
                  that.spinning = true;
                  that.tip = "开通批改服务中";
                  AllOpenedCorrectsApi({
                    businessType: that.orderType,
                    city: that.city,
                    correctFlag: that.correctFlag,
                    startTime: that.startTime,
                    endTime: that.endTime,
                    name: that.name,
                    orderNo: that.orderNo,
                    orderStatus: that.orderStatus,
                    payStatus: that.orderPayStatus,
                    payType: that.orderPayType,
                    province: that.province,
                    userName: that.userName,
                  }).then((res) => {
                    if (res.code == 200) {
                      that.spinning = false;
                      message.success(`开通${res.data.successCount}条成功`);
                    } else {
                      that.spinning = false;
                    }
                  });
                },
              });
            }
          }
        });
      }
    },
    // 导出全部订单按钮
    exportAllOrder () {
      this.spinning = true;
      this.tip = "订单导出中";
      this.isDisAllOrder = true;
      setTimeout(() => {
        this.isDisAllOrder = false;
      }, 3000);
      OrderInfoExportApi2({
        orderNo: this.orderNo, // 订单编号
        userName: this.userName, // 用户账号
        name: this.name, // 商品名称
        businessType: !this.orderType ? 0 : this.orderType,  // 订单类型
        orderStatus: !this.orderStatus ? 0 : this.orderStatus, // 订单状态
        payStatus: this.orderPayStatus ? this.orderPayStatus.toString() : this.orderPayStatus, // 支付状态
        payType: this.orderPayType ? this.orderPayType.toString() : this.orderPayType, // 支付方式
        province: this.province, // 省级code
        city: this.city, // 市级code
        startTime: this.startTime, // 下单日期-开始时间
        endTime: this.endTime, // 下单日期-结束时间
        userNo: this.userNo, // 用户编号-游客跳转订单时使用
        orders: this.orders, // 订单编号集合, 代理查看订单明细是使用
        correctFlag: this.correctFlag, // 是否开通批改权限 0:否 1:是
        deliveryStatus: this.deliveryStatus // 物流状态
      }).then(({ code, data }) => {
        if (code == 200) {
          if (data) {
            window.location.href = data;
            message.success("导出订单成功", 1);
          } else {
            message.error("筛选的订单数为0, 请重新选择");
          }
          this.spinning = false;
        } else {
          this.spinning = false;
        }
      });
    },
    // 开通批改服务的按钮
    correctingServiceClick () {
      let that = this;
      this.isDisOpenCorrectingService = true;
      if (this.selectedRowKeys.length <= 0) {
        message.error("请选择订单");
        setTimeout(() => {
          that.isDisOpenCorrectingService = false;
        }, 2000);
      } else {
        Modal.confirm({
          title: "提示",
          content: `是否为选中${that.selectedRows.length}个订单(用户)开通批改服务`,
          okText: "确认",
          cancelText: "取消",
          width: "500px",
          onOk () {
            OorrectsOpenApi(that.selectedRows).then(({ code, data }) => {
              if (code == 200) {
                that.isDisOpenCorrectingService = false;
                if (data.successCount == 0) {
                  Modal.error({
                    title: "提示",
                    content: `${data.failCount}个用户开通失败,请检查其订单状态`,
                    okText: "确认",
                    width: "500px",
                    onOk () {
                      that.GetOrdersFn();
                      that.selectedRowKeys = [];
                    },
                  });
                } else if (data.failCount == 0) {
                  Modal.success({
                    title: "提示",
                    content: `已成功为${data.successCount}个用户成功开通`,
                    okText: "确认",
                    width: "500px",
                    onOk () {
                      that.GetOrdersFn();
                      that.selectedRowKeys = [];
                    },
                  });
                } else if (data.failCount > 0 && data.successCount > 0) {
                  Modal.info({
                    title: "提示",
                    content: `已成功为${data.successCount}个用户成功开通,${data.failCount}个用户开通失败,请检查其订单状态`,
                    okText: "确认",
                    width: "500px",
                    onOk () {
                      that.GetOrdersFn();
                      that.selectedRowKeys = [];
                    },
                  });
                }
              } else {
                that.isDisOpenCorrectingService = false;
              }
            });
          },
          onCancel () {
            that.isDisOpenCorrectingService = false;
            that.selectedRowKeys = [];
          },
        });
      }
    },
    // 下载模板的按钮
    downloadTemplateclick () {
      this.isDisDownTemplate = true;
      setTimeout(() => {
        this.isDisDownTemplate = false;
      }, 3000);
      DownloadTemplateApi().then(({ code, data }) => {
        if (code == 200) window.location.href = data;
      });
    },
    // 订单导入中的
    spinningFn (bool) {
      this.tip = "订单导入中";
      this.spinning = bool;
    },
    // 导入订单返回的回调
    errOrderFn (data) {
      let that = this;
      let token = localStorage.getItem("token");
      let batchId = data.batchId
      this.dataList = data
      let url = `${domainName}/adminv2/order/info/import?action=2&batchId=${batchId}`
      axios.post(url, {}, {
          headers:{
            'accesstoken':token,
            'authorization':token,
            'Content-Type': 'application/json'
          }
        }).then((res) => {
        if (res.data.code == 1001) {
          that.timer = setInterval(that.regularTime, 5000)
        } else if (res.data.code == 200) {
          that.time = 0
          if (res.data.data.failCount > 0) {
            that.errImportVisible = true;
            that.errImportUrl = res.data.data.url;
            that.failCount = res.data.data.failCount;
          } else {
            that.spinning = false;
				    that.errImportVisible = false;
            Modal.success({
              title: "提示",
              content: `成功导入${res.data.data.successCount}条数据`,
              okText: "确认",
              cancelText: "",
              width: "500px",
              onOk () {
                that.errImportVisible = false;
              },
            });
            that.GetOrdersFn();
          }
        } else {
          that.time = 0
        }
      });
    },
    // 定时器
    regularTime () {
      let that = this;
      let token = localStorage.getItem("token");
      that.time++;
      let batchId = that.dataList.batchId
      console.log(that.time);
      if (that.time < 25) {
        let url = `${domainName}/adminv2/order/info/import?action=2&batchId=${batchId}`
        axios.post(url,{}, {
          headers:{
            'accesstoken':token,
            'authorization':token,
            'Content-Type': 'application/json'
          }
        }).then((res) => {
          if (res.data.code == 200) {
            if (res.data.data.failCount > 0) {
              that.errImportVisible = true;
              that.errImportUrl = res.data.data.url;
              that.failCount = res.data.data.failCount;
              clearInterval(that.timer);
            } else {
              that.spinning = false;
				      that.errImportVisible = false;
              Modal.success({
                title: "提示",
                content: `成功导入${res.data.data.successCount}条数据`,
                okText: "确认",
                cancelText: "",
                width: "500px",
                onOk () {
                  that.errImportVisible = false;
                },
              });
              that.GetOrdersFn();
              clearInterval(that.timer);
              that.time = 0
            }
          }
        });
      } else {
        this.spinning = false;
        this.errImportVisible = false;
        clearInterval(that.timer);
        that.$message.error('订单导入失败')
        that.time = 0
      }
    },
    // 商品类型改变的回调
    changeOrderType (value) {
      this.pageNo = 1;
      this.orderType = value;
      this.GetOrdersFn();
    },
    // 订单状态改变的回调
    changeOrderStatus (value) {
      this.pageNo = 1;
      this.orderStatus = value;
      this.GetOrdersFn();
    },
    // 支付状态改变的回调
    changeOrderPayStatus (value) {
      this.pageNo = 1;
      this.orderPayStatus = value;
      this.GetOrdersFn();
    },
    // 支付方式改变的回调
    changeOrderPayType (value) {
      this.pageNo = 1;
      this.orderPayType = value;
      this.GetOrdersFn();
    },
    // 改变物流状态回调
    changedeliveryStatus (value) {
      this.pageNo = 1;
      this.deliveryStatus = value;
      this.GetOrdersFn();
    },
    // 批改状态改变的回调
    changeCorrectFlag (value) {
      this.pageNo = 1;
      this.correctFlag = value;
      this.GetOrdersFn();
    },
    // 省级改变的回调
    changeProvince (value) {
      this.pageNo = 1;
      this.province = value;
      this.city = "";
      this.cityArr = [{ code: "", name: "所有市" }];
      this.GetOrdersFn();
      this.GetAllCityByCodeFn();
    },
    // 市级改变的回调
    changeCity (value) {
      this.pageNo = 1;
      this.city = value;
      this.GetOrdersFn();
    },
    // 下单日期改变
    onChangeDate (date, dateString) {
      if (dateString[0] != "") {
        this.startTime = dateString[0] + " 00:00:00";
        this.rangePicker = dateString;
        this.endTime = dateString[1] + " 23:59:59";
      } else {
        this.startTime = "";
        this.endTime = "";
        this.rangePicker = [];
      }
    },
    // 回车和搜索的按钮
    searchClick () {
      this.tableLoading = true;
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.GetOrdersFn();
    },
    // 重置按钮
    resetClick () {
      this.name = "";
      this.orderNo = "";
      this.userName = "";
      this.orderType = "";
      this.orderStatus = "";
      this.orderPayStatus = "";
      this.orderPayType = "";
      this.correctFlag = "";
      this.province = "";
      this.city = "";
      this.startTime = "";
      this.endTime = "";
      this.rangePicker = [];
    },
    // 申请退款
    async ApplyRefund (record) {
      const { code } = await ApplyRefundV2Api({ orderNo: record.orderNo })
      if (code !== 200) return
      this.$message.success("申请退款成功", 2)
      record.refundStatus = 1
    },
    // 退款的按钮
    refundClick (record) {
      const _that = this
      // 申请退款
      if (record.refundStatus == 0) {
        Modal.confirm({
          title: "提示",
          content: "退款后用户相关权限会取消且无法恢复, 是否确定申请退款",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          width: "500px",
          onOk () {
            _that.ApplyRefund(record)
          },
        });
      } else if (record.refundStatus == 1) { // 确认退款
        Modal.confirm({
          title: "提示",
          content: "退款后用户相关权限会取消且无法恢复, 是否确定确认退款",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          width: "500px",
          onOk () {
            _that.refundOrder(record)

          },
        });
      }
    },
    // 确认退款
    async refundOrder (record) {
      const params = { orderNo: record.orderNo }
      const { code } = await OrderInfoRefundV2Api(params)
      if (code !== 200) return
      this.$message.success("确认退款成功", 2)
      record.refundStatus = 2
      this.GetOrdersFn()
    },
    // 驳回申请退款按钮
    rejectClick (record) {
      const _that = this
      Modal.confirm({
        title: "提示",
        content: "驳回退款后如有需要可重新申请,是否确认驳回",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        width: "500px",
        onOk () {
          _that.rejectOrder(record)
        },
      });
    },
    // 驳回退款
    async rejectOrder (record) {
      const { code } = await refuseRefundV2API({ orderNo: record.orderNo })
      if (code !== 200) return
      this.$message.success('驳回申请退款成功', 2)
      record.refundStatus = 0
    },
    // 回收权益按钮
    recoveryRightsClick (record) {
      Modal.confirm({
        title: "提示",
        content:
          "回收权益后,用户需要重新购买方可继续使用,系统不会自动退款, 需您人工联系用户退款",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        width: "500px",
        centered: true,
        onOk () {
          RecoveryRights({ orderNo: record.orderNo }).then(({ code }) => {
            if (code == 200) {
              message.success("回收权益成功", 2);
              record.orderStatusText = "权益已回收";
            }
          });
        },
      });
    },
    // 收货地址修改弹窗
    showReceiptAddressDlg (record) {
      this.currentRecord = record;
      this.addressModalVisible = true;
    },
    // 保存收货地址
    updateOrderReceiptAddress (data) {
      console.log('## data', data)
      this.addressModalVisible = false;
      const {
        orderNo,
        receiptAddress,
        receiptArea,
        receiptName,
        receiptPhone
      } = data;
      const params = {
        orderNo,
        receiptAddress,
        receiptArea,
        receiptName,
        receiptPhone
      };
      SaveOrderReceiptAddressApi(params).then(({ code }) => {
        if (code == 200) {
          message.success("修改成功");
          this.GetOrdersFn();
        }
      }).catch((err) => {
        console.error(err);
      })
    },
    // 快递信息按钮
    expressClick (record) {
      record.expressNo == "——"
        ? (this.inpVal = "")
        : (this.inpVal = record.expressNo);
      this.currentRecord = record;
      this.modalVisible = true;
    },
    // 快递信息模态框的确定按钮
    ok (inpVal) {
      if (inpVal) {
        this.inpVal = inpVal;
        GetOrdersExpressNoApi({
          orderNo: this.currentRecord.orderNo,
          expressNo: this.inpVal,
        }).then(({ code }) => {
          if (code == 200) {
            message.success("修改成功");
            this.GetOrdersFn();
          }
        });
      }
      this.modalVisible = false;
    },
    // 第三方信息按钮
    threeNewsClick (record) {
      this.currentRecord = record;
      this.threeNewsVisible = true;
    },
    // 第三方信息的确定按钮
    threeNewsOk (obj) {
      if (
        obj.thirdPartyOrderNo ||
        obj.thirdPartyPhone ||
        obj.thirdPartyChannel
      ) {
        OrderThirdPartyApi({
          orderNo: this.currentRecord.orderNo,
          thirdPartyChannel: obj.thirdPartyChannel,
          thirdPartyOrderNo: obj.thirdPartyOrderNo,
          thirdPartyPhone: obj.thirdPartyPhone,
        }).then(({ code }) => {
          if (code == 200) {
            message.success("设置成功");
            this.GetOrdersFn();
          }
        });
      }
      this.threeNewsVisible = false;
    },
    // 列表开通批改服务的按钮
    openCorrectingService (record) {
      let that = this;
      Modal.confirm({
        title: "提示",
        content: "是否为该订单(用户)开通批改服务",
        okText: "确认",
        cancelText: "取消",
        width: "500px",
        onOk () {
          let arr = [];
          arr.push({
            courseNo: record.businessNo,
            orderNo: record.orderNo,
            phone: record.userName,
          });
          return new Promise((resolve, reject) => {
            OorrectsOpenApi(arr).then(({ code, data }) => {
              if (code == 200) {
                if (data.successCount > 0) {
                  message.success("开通成功");
                } else {
                  message.error("开通失败");
                }
                that.GetOrdersFn();
              }
            });
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => {
          });
        },
      });
    },
    // 订单详情的按钮
    orderDetailClick (record) {
      localStorage.setItem("pageNo", this.pageNo);
      location.href = `/orderformManage/orderformList/orderDetail/${record.orderNo}`;
    },
    // 修改物流状态按钮点击
    changeDeliveryStatusClick (record) {
      this.currentRecord = record;
      const currStatusText = this.deliveryStatusText[record.deliveryStatus];
      const nextStatusArr = +record.deliveryStatus === 1 ? [1, 3] : [3, 1]; // 用数组的形式获取最后一个状态，当前状态是1 未发货, 则最后一个状态值是3（不可发货）
      this.changeDeliveryStatusVal = nextStatusArr[1];
      const nextStatusText = this.deliveryStatusText[this.changeDeliveryStatusVal];
      this.changeDeliveryStatusTips = `是否将物流状态由"${currStatusText}"改为"${nextStatusText}"`;
      this.isShowChangeDeliveryStatusDlg = true;
    },
    // 修改物流状态弹窗确认
    saveChangeDeliveryStatus () {
      SaveDeliveryStatusApi({
        deliveryStatus: this.changeDeliveryStatusVal,
        orderNo: this.currentRecord.orderNo
      }).then(({ code, data }) => {
        if (code == 200) {
          this.isShowChangeDeliveryStatusDlg = false;
          message.success("修改成功");
          this.GetOrdersFn();
        }
      }).catch(() => {
      });
    },
    // 点击账号
    userClick (record) {
      console.log(record.userName);
      if (this.isShowUserList && record.userName != "——") {
        localStorage.setItem("phone", record.userName);
        location.href = "/crm/clientManage";
      }
    },
    // 分页功能切换的回调
    showSizeChangeFn (current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.GetOrdersFn();
    },
    // 获取订单列表函数
    GetOrdersFn () {
      this.tableLoading = true;
      GetOrdersApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        orderNo: this.orderNo, // 订单编号
        userName: this.userName, // 用户账号
        name: this.name, // 商品名称
        businessType: !this.orderType ? 0 : this.orderType,  // 订单类型
        orderStatus: !this.orderStatus ? 0 : this.orderStatus, // 订单状态
        payStatus: this.orderPayStatus ? this.orderPayStatus.toString() : this.orderPayStatus, // 支付状态
        payType: this.orderPayType ? this.orderPayType.toString() : this.orderPayType, // 支付方式
        province: this.province, // 省级code
        city: this.city, // 市级code
        startTime: this.startTime, // 下单日期-开始时间
        endTime: this.endTime, // 下单日期-结束时间
        userNo: this.userNo, // 用户编号-游客跳转订单时使用
        orders: this.orders, // 订单编号集合, 代理查看订单明细是使用
        correctFlag: this.correctFlag, // 是否开通批改权限 0:否 1:是
        deliveryStatus: this.deliveryStatus // 物流状态
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          data.data.forEach((item) => {
            item.key = item.orderNo;
            item.expressNo = item.expressNo == "" ? "——" : item.expressNo;
            item.businessName =
              item.businessName == "" ? "——" : item.businessName;
            item.region = item.region == "" ? "——" : item.region;
            item.userName = item.userName == "" ? "——" : item.userName;
          });
          this.tableData = data.data;
          this.count = data.count;
        }
      });
    },
    // 获取所有省份
    ShowAllProvinceFn () {
      ShowAllProvinceApi().then(({ code, data }) => {
        if (code == 200) this.provinceArr.push(...data);
      });
    },
    // 根据省级code获取所有市级
    GetAllCityByCodeFn () {
      GetAllCityByCodeApi({ code: this.province }).then(({ code, data }) => {
        if (code == 200) this.cityArr.push(...data);
      });
    },
    // 回到顶部的函数
    targetFn () {
      return document.querySelector("#order_list");
    },
  },
};
</script>

<style lang="less" scoped>
#order_list {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.top {
  margin-top: 15px;

  .top_line {
    margin-bottom: 20px;
    display: flex;

    span {
      margin-right: 20px;

      /deep/ .ant-input {
        width: 300px;
      }

      /deep/ .ant-select-selection {
        width: 110px;
        margin-left: 10px;
      }

      /deep/ .ant-calendar-picker-input {
        margin-left: 10px;
      }
    }

    .ant-btn {
      margin-right: 20px;
    }
  }
}

.marign_left20 {
  margin-left: 20px;
}

/deep/ .ant-upload-picture-card-wrapper {
  width: auto;
}

/deep/ .ant-table-wrapper {
  margin-top: 20px;
}

/deep/ .ant-table-thead>tr>th {
  font-weight: 600;
}

/deep/ .ant-table-fixed-right {
  z-index: 99;
}

/deep/ .ant-table-fixed-header .ant-table-body-inner {
  background-color: #fff;
  z-index: 99;
}
</style>
